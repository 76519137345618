import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import moment from "moment";
Vue.component('downloadExcel', JsonExcel)
import vSelect from 'vue-select'


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin,
        vSelect
    },
    data: function() {
        return {
            pageTitle: this.$t('etat_clients.page_titre'),
            point_de_ventes_id: "",
            point_de_ventes: "",
            filtreDate: {
                dateDebut: "",
                dateFin: ""
            },
            montant_total: "0",
            listeTypeDepense: [],
            vuetable: {
                moreParams: {},
                fields: [{
                        name: 'nom_client',
                        title: this.$t('etat_clients.nom_client'),
                        sortField: 'nom_client',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'adresse_client',
                        title: this.$t('etat_clients.adresse'),
                        sortField: 'adresse_client',
                        dataClass: 'text-left',
                        titleClass: "text-left",
                    },
                    {
                        name: 'tel_client',
                        title: this.$t('etat_clients.telephone'),
                        sortField: 'tel_client',
                        dataClass: 'text-center',
                        titleClass: "text-center",
                        width: "200px",
                    },
                    {
                        name: 'total_a_payer',
                        title: this.$t('etat_clients.total_achat'),
                        sortField: 'total_a_payer',
                        dataClass: 'text-right text-bold',
                        titleClass: "text-center",
                        width: "150px",
                    },
                    {
                        name: 'total_deja_paye',
                        title: this.$t('etat_clients.total_paye'),
                        sortField: 'total_deja_paye',
                        dataClass: 'text-right text-bold text-success',
                        titleClass: "text-center",
                        width: "150px",
                    },
                    {
                        name: 'total_restant',
                        title: this.$t('etat_clients.reste_a_payer'),
                        sortField: 'total_restant',
                        dataClass: 'text-right text-bold text-danger',
                        titleClass: "text-center",
                        width: "150px",
                    },
                    {
                        name: 'actions',
                        width: "70px",
                        dataClass: "text-center"
                    },

                ],
                sortOrder: [
                    { field: 'nom_client', direction: 'asc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Nom clients': 'nom_client',
                    'Adresse': 'adresse_client',
                    'Téléphone': 'tel_client',
                    'Total achat': 'montant_factureTotal',
                    'Total payé': 'montant_payerTotal',
                    'Reste à payer': 'reste_payerTotal',
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            motCle: "",
            criteriacol: "",
            crudform: {
                id: "",
                date: "",
                type_depense: "",
                beneficiaire: "",
                montant: "",
                motif: "",
                date_enregistrement: ""
            },
            crudmodaltitle: "Ajouter une dépense",
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            somme_compte_client: {
                montant_factureTotal: '',
                montant_payerTotal: '',
                reste_payerTotal: '',
            }

        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.point_de_ventes_id = this.point_de_ventes_id;
            Vue.nextTick(() => this.$refs.vuetable.refresh());

            var that = this;
            axios.post(that.BASE_URL + "/clients/fetchmontanttotaletat", this.vuetable.moreParams).then(function(response) {
                console.log(response);
                that.somme_compte_client = response.data[0];
            });
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("crudmodal");
        },
        closeModal() {
            this.$bvModal.hide("crudmodal");
        },
        loadPointDeVente() {
            axios.get(this.BASE_URL + "/pointvente/getall/").then(response => {
                //console.log(response.data[0]);
                this.point_de_ventes = response.data;
            });
        },
    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);

        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;
        this.setFilter();

        this.loadPointDeVente();
    },
    mounted: function() {

    }
}